import { DashboardGaugeIcon } from '@panwds/icons';

export default ({ sparkyFramework: { onAuthed }, vars }) => {
  // eslint-disable-next-line no-undef
  const envName = (vars ?? {}).env_name || process.env.ENV_NAME;
  // eslint-disable-next-line no-undef
  console.info(`ADEM UI Env: ${envName}`);
  // eslint-disable-next-line no-undef
  console.info(`ADEM UI Version: ${process.env.APP_VERSION}`);

  if (envName === 'staging') {
    // eslint-disable-next-line no-undef
    console.info(
      '%c Release Changelog: ',
      'background: #222; color: #bada55',
      `https://prisma-access.docs.pan.run/sase-adem-group/dem-ui/changelog.txt`
    );
  }

  onAuthed(({ getLicenseInfo, getFqdnInfo, getAuthInfo }) => {
    getLicenseInfo().then((res) => {
      let tenantId = '';
      // Use the logging_service tenant ID
      if (res?.main?.tenant?.info?.associations) {
        res.main.tenant.info.associations.forEach((association) => {
          if (association.app_id === 'logging_service') {
            tenantId = association.tenant_id;
          }
        });
      }
      console.log('onAuthed: ', tenantId, vars);
    });
    getFqdnInfo();
    getAuthInfo();
  });
  return import('./routes').then((routes) => ({
    key: 'dem',
    title: 'Autonomous DEM',
    icon: DashboardGaugeIcon,
    contentClassName: 'dem-root',
    path: '/dem',
    exact: false,
    noRBACheck: true,
    expandable: true,
    mergeNav: true,
    children: routes.default,
    component: () => import('./DemViewer'),
    featureAvil: 'dem',
  }));
};
